import { clsx } from 'clsx'
import { type FC } from 'react'

import { type StoryblokComponentProps } from '../../model'
import { storyAccessorGetBorderRadius } from '../../model/storyAccessors/storyAccessorGetBorderRadius'
import { type StoryblokCardType } from '../../model/storyblokTypes.generated'
import { StoryblokComponentsRenderer } from '../StoryblokComponentsRenderer'

export const StoryblokCard: FC<StoryblokComponentProps<StoryblokCardType>> = ({
  blok: { background, content, padding, rounded },
}) => {
  return (
    <div
      className={clsx(
        'flex flex-row items-center justify-center gap-6',
        storyAccessorGetBorderRadius(rounded),
      )}
      style={{ background, padding }}
    >
      <StoryblokComponentsRenderer bloks={content} />
    </div>
  )
}
